import type { FC } from 'react';
import { useAuth } from 'hooks/use-auth';
import { useEffect, useState } from "react";
import PeerdwebService from "services/peerdweb/peerdweb-service";
import { DateRangeSearchRequest } from "types/analytics/date-range-search-request";
import { addDays, formatISO } from "date-fns";
import { Chart } from "components/analytics/chart";
import { ApexOptions } from "apexcharts";
import { PeerdwebColours } from "theme/peerdweb-colours";

export const DownloadedUsersChart: FC = () => {
    const auth = useAuth();

    const peerdwebService = new PeerdwebService();
    const [topDownloadedUsersSeries, setTopDownloadedUsersSeries] = useState<ApexAxisChartSeries>();

    const today = new Date();
    const [dateRange, setDateRange] = useState<DateRangeSearchRequest>({
        startDate: new Date(formatISO(addDays(today, -365), { representation: 'date' })),
        endDate: new Date(formatISO(today, { representation: 'date' })),
    });

    const getTopDownloadedUsers = async () => {
        const results = await peerdwebService.getTopDownloadedUsers(dateRange);

        const series: ApexAxisChartSeries = [
            {
                name: "Downloads",
                data: results.data.map((userDownloads): { x: any, y: any } => {
                    return {
                        x: userDownloads.user,
                        y: userDownloads.downloads,
                    }
                }),
            }
        ]
        setTopDownloadedUsersSeries(series);
    };

    useEffect(() => {
        getTopDownloadedUsers();
    }, [dateRange]);

    const chartOptions: ApexOptions = {
        tooltip: {
            enabled: false,
        },
        chart: {
            background: "transparent",
            animations: {
                enabled: true,
                easing: "easein",
                speed: 400,
                animateGradually: {
                    enabled: true,
                    delay: 150,
                },
                dynamicAnimation: {
                    enabled: false,
                },
            },
        },
        plotOptions: {
            bar: {
                horizontal: true,
            },
        },
        colors: [
            PeerdwebColours.Navy,
            PeerdwebColours.Navy1,
            PeerdwebColours.Navy2,
            PeerdwebColours.Navy3,
        ],
        fill: {
            opacity: 1,
            type: "solid",
        },
        grid: {
            show: false,
        },
        title: {
            text: "Users Downloaded",
        },
        xaxis: {
            labels: {
                show: false,
            }
        },
    };

    return (
        <>
            {topDownloadedUsersSeries && <Chart height={320} width={"100%"} options={chartOptions} series={topDownloadedUsersSeries} type="bar" />}
        </>
    );
};
